import { Select, DatePicker, Space } from 'antd';
import { useEffect, useState } from 'react';
import { getListUsers, getStatisticUser } from '../../requests/user.request.js';
import { Column } from '@ant-design/charts';
import { Heatmap } from '@ant-design/plots';
import { GeneralHealth } from '../../components/GeneralHealth.jsx';
import { GeneralCalendar } from '../../components/GeneralCalendar.jsx';
import { LoadingBox, LineMultipleComponent } from '../general/GeneralPage.jsx';
import * as dayjs from 'dayjs';
const { RangePicker } = DatePicker;

export const IndividualPage = () => {
    const [userOptions, setUserOptions] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [data, setData] = useState();
    const [datepicker, setDatePicker] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [statistic, setStatistic] = useState(0);

    useEffect(() => {
        setDatePicker({ start: getDate('start'), end: getDate('end') });
        getUsers();
    }, []);

    const handleChangeStatistics = (e) => {
        setStatistic(e);
    };

    const getUsers = async () => {
        const data = await getListUsers();

        setUserOptions(
            data.map((e) => ({
                value: e.user_id,
                label: e.user_id + ' ' + e.name,
            })),
        );
    };

    useEffect(() => {
        if (selectedUser && datepicker.start && datepicker.end) {
            getData(selectedUser);
        }
    }, [selectedUser, datepicker]);

    const getData = async (user_id) => {
        setIsLoading(true);
        const form = Object.assign({ user_id }, datepicker);
        const { result } = await getStatisticUser(JSON.stringify(form));
        if (result) {
            const {
                general_statistic: {
                    general_health,
                    general_sentiment,
                    score_mental: physical,
                    score_physical: mental,
                } = {},
                statistic_weekday: statistic_weekday = [],
                statistic_month: statistic_month = [],
                sentiment: sentiment = [],
                calendar: calendar = [],
                matrix: matrix = [],
                event_check_out: check_out = [],
                event_check_in: check_in = [],
                line_chart: line_chart = [],
                
            } = result;
            setData({
                general: {
                    general_health,
                    general_sentiment,
                    physical,
                    mental,
                },
                statistic_weekday,
                statistic_month,
                sentiment,
                calendar,
                matrix,
                check_out,
                check_in,
                line_chart,
            });
            setIsLoading(false);
        } else {
            setData({
                general: {
                    general_health: 0,
                    general_sentiment: 0,
                    physical: 0,
                    mental: 0,
                },
                statistic_weekday: [],
                statistic_month: [],
                sentiment: [],
                calendar: [],
                matrix: [],
                check_out: [],
                check_in: [],
                line_chart: [],
            });
            setIsLoading(false);
        }
    };

    const getDate = (type) => {
        const date = new Date();
        if (type === "start") {
            date.setDate(date.getDate() - 30);
        }
        const currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        return currentDate;
    };

    return (
        <div className="individual">
            <div className="filter">
                <Space direction="horizontal">
                    <Select
                        className="xl:min-w-100"
                        options={userOptions}
                        value={selectedUser}
                        onChange={setSelectedUser}
                        dropdownMatchSelectWidth={false}
                        showSearch
                        optionLabelProp="label"
                        optionFilterProp="label"
                    />
                    <RangePicker
                        defaultValue={[dayjs(getDate('start')), dayjs(getDate('end'))]}
                        allowClear={true}
                        onChange={(d, s) => setDatePicker({ start: s[0], end: s[1] })}
                    />
                </Space>
            </div>

            {data && (
                <div className="statistics mt-6 grid grid-col-1 xl:grid-cols-6 gap-8">
                    <div className="card xl:col-span-3">
                        {isLoading ? (
                            <LoadingBox />
                        ) : (
                            <Space direction="vertical" size={140} className="h-full w-full">
                                <p className="text-center">総合的な評価</p>
                                <GeneralHealth values={data.general} />{' '}
                            </Space>
                        )}
                    </div>
                    <div className="card xl:col-span-3">
                        <GeneralCalendar start={datepicker.start} end={datepicker.end} calendar={data.calendar} />
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">
                                        {statistic === 0 ? '曜日別の健康指数' : '月別の平均健康指数'}
                                    </p>
                                    <div className="flex justify-end">
                                        <Select
                                            className="w-20"
                                            defaultValue={statistic}
                                            options={[
                                                {
                                                    value: 0,
                                                    label: '週間',
                                                },
                                                {
                                                    value: 1,
                                                    label: '月間',
                                                },
                                            ]}
                                            onChange={handleChangeStatistics}
                                        />
                                    </div>
                                    {statistic === 0 ? (
                                        <Column
                                            data={data.statistic_weekday}
                                            isGroup
                                            xField="day"
                                            yField="value"
                                            seriesField="type"
                                            color={['#c1ddb2', '#c6babd']}
                                        ></Column>
                                    ) : (
                                        <Column
                                            data={data.statistic_month}
                                            isGroup
                                            xField="month"
                                            yField="value"
                                            seriesField="type"
                                            color={['#c1ddb2', '#c6babd']}
                                        ></Column>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            <div className="h-full">
                                {isLoading ? (
                                    <LoadingBox />
                                ) : (
                                    <div>
                                        <p className="text-center pb-8">感情分析</p>
                                        <Column
                                            data={data.sentiment}
                                            xField="sentiment"
                                            yField="指数"
                                            legend={{ position: 'top-right' }}
                                            color={'#f7d3e2'}
                                            xAxis={{
                                                label: {
                                                    style: {
                                                        fontSize: 8,
                                                    },
                                                },
                                            }}
                                        ></Column>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">日ごとの健康状態</p>
                                    <LineMultipleComponent data={data.line_chart} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-8">体温と感情の相関関係</p>
                                    <Heatmap
                                        data={data.matrix}
                                        xField="temperature"
                                        yField="sentiment"
                                        colorField="指数"
                                        color={['#174c83', '#0d5fbb', '#7eadfc', '#fd8b6f', '#aa3523']}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">出勤時の出来事と健康（総合）の相関関係</p>
                                    <Heatmap
                                        data={data.check_in}
                                        xField="range_score"
                                        yField="event"
                                        colorField="指数"
                                        color={['#174c83', '#0d5fbb', '#7eadfc', '#fd8b6f', '#aa3523']}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="card xl:col-span-3">
                        <div className="h-full">
                            {isLoading ? (
                                <LoadingBox />
                            ) : (
                                <div>
                                    <p className="text-center pb-4">退勤時の出来事と健康（総合）の相関関係</p>
                                    <Heatmap
                                        data={data.check_out}
                                        xField="range_score"
                                        yField="event"
                                        colorField="指数"
                                        color={['#174c83', '#0d5fbb', '#7eadfc', '#fd8b6f', '#aa3523']}
                                        legend={{ position: 'top-right' }}
                                        label={{ color: '#fff' }}
                                        tooltip={{ showMarkers: false }}
                                    ></Heatmap>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
