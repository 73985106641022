import { useQuery } from '@tanstack/react-query';
import { getStatisticAll } from '../requests/user.request';

const KEY = 'general';

export const useGeneralData = (datepicker) => {
    return useQuery({
        queryKey: [KEY, datepicker],
        refetchOnWindowFocus: false,
        queryFn: async () => await getStatisticAll(JSON.stringify(datepicker)),
    });
};
