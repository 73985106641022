import { Calendar, Space, Tooltip, Col, Row, Select} from 'antd';
import { useState } from 'react';
import * as dayjs from 'dayjs';
import { background } from '../constants/bg.constant';

const GOOD = 90;
const BAD = 70;
const MOUNTH_JA = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']

export const GeneralCalendar = ({ start, end, calendar }) => {
    const [startDate, setStartDate] = useState(dayjs(start));

    function handleFullRender(val) {
        const date = val.date();
        let calendar_day = '';
        calendar.forEach((each) => {
            if (val.diff(dayjs(each.day)) === 0) {
                if (each.score_health <= BAD) calendar_day = background.BAD;
                else if (BAD < each.score_health && each.score_health < GOOD) calendar_day = background.NORMAL;
                else calendar_day = background.GOOD;
                calendar_day += " text-white inline-block w-5"
            } 
        })
        return <div><span className={calendar_day}>{date}</span></div>;
    }

    const renderHeader = ({ value, onChange }) => {
        const options = [];
        for (let i = dayjs(start).year(); i <= dayjs(end).year(); i++) {
            options.push(
                <Select.Option key={i} value={i} className="year-item">
                    {i}年
                </Select.Option>,
            );
        }
        return (
            <div className="p-2">
                <Row gutter={8} align="middle">
                    <Col>
                        <Select
                            className="my-year-select"
                            value={value.year()}
                            onChange={(newYear) => {
                                const now = value.clone().year(newYear);
                                onChange(now);
                            }}
                        >
                            {options}
                        </Select>
                    </Col>
                    <Col>
                        <Select
                            value={value.month()}
                            onChange={(newMonth) => {
                                const now = value.clone().month(newMonth);
                                onChange(now);
                            }}
                        >
                            {MOUNTH_JA.map((month, i) => (
                                <Select.Option key={i} value={i} className="month-item">
                                    {month}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div>
            <Calendar
                validRange={[dayjs(start), dayjs(end)]}
                value={startDate}
                fullscreen={false}
                mode="month"
                onPanelChange={(e, t) => setStartDate(e)}
                fullCellRender={handleFullRender}
                className="calendar"
                headerRender={renderHeader}
            />
            <Space className="ml-3 mt-3" direction={"vertical"}>
                <Space>
                    <Tooltip title="良好" color="#38E54D">
                        <div className='w-3 h-3 cursor-pointer bg-[#38E54D]'></div>
                    </Tooltip>
                    <div>良好＝総合スコア80〜100</div>
                </Space>
                <Space>
                    <Tooltip title="普通" color="#008eff">
                        <div className='w-3 h-3 cursor-pointer bg-[#008eff]'></div>
                    </Tooltip>
                    <div>普通＝総合スコア60〜80</div>
                </Space>
                <Space>
                    <Tooltip title="不良" color="#FA7070">
                        <div className='w-3 h-3 cursor-pointer bg-[#FA7070]'></div>
                    </Tooltip>
                    <div>不良＝総合スコア0〜60</div>
                </Space>
            </Space>
        </div>
    );
};
